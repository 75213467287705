import React, { useEffect, useState } from 'react';
import './App.css';
// import "swiper/swiper.min.css";
// import { Navigation, Pagination, Autoplay } from "swiper";
// import { Swiper, SwiperSlide } from 'swiper/react';
import Lenis from '@studio-freight/lenis';
import SeedPopup from './component/SeedPopup';

function App() {

  const [show, setShow] = useState(false);

  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.5,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

  }, [])


  return (
    <>
      <div className='top_bar'>
        <div className='d-flex align-items-center gap-md-2 gap-1'>
          <p>Our Only verified</p>
          <img src='assets/image/verify.svg' alt='verify' />
        </div>
        <div className='d-flex align-items-center gap-md-2 gap-1'>
          <a href='https://t.me/Faithprotocol_official' target='_blank' className='presale_link'>Telegram Handle
            <img src='assets/image/telegram_topbar.svg' alt='telegram' className='mx-2' />
            <span className='text-decoration-underline'>@Faithprotocol_official</span>
          </a>
        </div>
      </div>

      <nav className="navbar navbar-expand-xl">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src='assets/image/logo.svg' alt='Faith Protocol Logo' />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <img src='assets/image/menu.svg' alt='menu' />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-4 mb-xl-0">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="https://portal.faithprotocol.io/buy-token" target='_blank'>Seed Round</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://portal.faithprotocol.io/public-sale" target='_blank'>Public Sale</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://faithmeta.land/" target='_blank'>Faith Metaverse</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://www.faithprotocol.com/" target='_blank'>Official Site</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://portal.faithprotocol.io/wallet" target='_blank'>Wallet</a>
              </li>             
              <li className="nav-item dropdown drop_menu">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Documents
                </a>
                <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLink">
                  <li><a className="dropdown-item" href="https://faith-protocol.gitbook.io/faith-protocol-whitepaper" target='_blank'>Whitepaper</a></li>
                  <li><a className="dropdown-item" href='assets/pdf/Faith-Protocol-Refrral.pdf' download >Faith Presale & Referral</a></li>                 
                </ul>
              </li>
            </ul>
            <div className="d-flex align-items-center gap-lg-2 gap-3">
              <a href='https://portal.faithprotocol.io/' target='_blank' className="btn signin_btn account_btn">Buy Now</a>
              <a href='https://metahuman.faithmeta.land' target='_blank' className="btn signin_btn account_btn">Metahuman</a>
            </div>
          </div>
        </div>
      </nav>

      <section className='hero_bg'>
        <div className='container'>
          <div className='hero_content'>
            <div className='hero_link'>
              <div className='hero_div d-flex align-items-center gap-2'>
                <p>🎉 Announcing Faith Protocol Pre-sale</p>
                {/* <img src='assets/image/arrow_right.svg' alt='arrow right' /> */}
              </div>
            </div>
            <div className='d-md-flex align-items-center gap-3'>
              <h1 className='hero_title'>Presale is <br className='d-md-none d-block' /> <span className='text_color_gradient'>Live Now!</span></h1>
              <div className='d-flex align-items-center justify-content-center gap-md-2 gap-3 mt-md-0 mt-2'>
                <div className='presale_icon'>
                  <img src='assets/image/bnb.svg' alt='bnb' />
                </div>
                <div className='presale_icon'>
                  <img src='assets/image/usdt.svg' alt='usdt' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className='hero_border'></div>

      <section className='section_detail presale_section'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-10'>
              <div className='presale_flex'>
                <div className='presale_flex flex-md-row'>
                  <h2 className='presale_title gredient_text'>Presale</h2>
                  <div className='presale_raised'>
                    <a href='https://portal.faithprotocol.io/' target='_blank'>Join now!!</a>
                  </div>
                </div>
                <p className='presale_content text-center'>The Faith Protocol presale is officially live! Don’t miss your chance to start securing exclusive benefits and be at the forefront of digital innovation. Join now and step into the future with us!</p>
                <div className='d-flex align-items-center gap-2'>
                  <a href='https://portal.faithprotocol.io/' target='_blank' className='signin_btn'>Join Presale</a>
                  <a href='#' className='signin_btn account_btn'>How to buy</a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section className='section_detail presale_section presale_bg'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8 order-lg-1 order-2'>
              <h2 className='presale_title gredient_text'>What Is</h2>
              <span className='presale_title gredient_text'>Faith Protocol?</span>
              <h3 className='presale_sub_title'>Pioneering the Digital Frontier</h3>
              <p className='presale_content text-start'>Faith Protocol redefines the Web3 landscape with a robust blockchain ecosystem, integrating digital identity, AI-powered assistance, and immersive metaverse experiences. Anchored by Faith Currency, it paves the way for a seamless, innovative digital future.
              </p>
            </div>
            <div className='col-lg-4 text-lg-end text-center order-lg-2 order-1'>
              <img src='assets/image/faith_coin.png' alt='faith protocol' />
            </div>
          </div>

          <div className='partner_margin'>
            <h4 className='news_title gredient_text'>Our Partner</h4>
            <div className='partner_div'>
              <div className='partner_logo'>
                <img src='assets/image/partners/unity.svg' alt='faith protocol partner unity' />
              </div>
              <div className='partner_logo'>
                <img src='assets/image/partners/unreal.svg' alt='faith protocol partner unreal' />
              </div>
              <div className='partner_logo'>
                <img src='assets/image/partners/blender.svg' alt='faith protocol partner blender' />
              </div>
              <div className='partner_logo'>
                <img src='assets/image/partners/amazon.svg' alt='faith protocol partner amazon' />
              </div>
              <div className='partner_logo'>
                <img src='assets/image/partners/azure.svg' alt='faith protocol partner azure' />
              </div>
              <div className='partner_logo'>
                <img src='assets/image/partners/meta.svg' alt='faith protocol partner meta' />
              </div>
              <div className='partner_logo'>
                <img src='assets/image/partners/trust.svg' alt='faith protocol partner trust' />
              </div>
              <div className='partner_logo'>
                <img src='assets/image/partners/photon.svg' alt='faith protocol partner photon' />
              </div>
              <div className='partner_logo'>
                <img src='assets/image/partners/readyplayerme.svg' alt='faith protocol partner readyplayerme' />
              </div>
              <div className='partner_logo'>
                <img src='assets/image/partners/googlecloud.svg' alt='faith protocol partner googlecloud' />
              </div>
              <div className='partner_logo'>
                <img src='assets/image/partners/intel.svg' alt='faith protocol partner intel' />
              </div>
              <div className='partner_logo'>
                <img src='assets/image/partners/nvidia.svg' alt='faith protocol partner nvidia' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='ecosystem' className='section_detail presale_section'>
        <div className='container'>
          <h2 className='presale_title gredient_text'>Our</h2>
          <span className='presale_title gredient_text'>Ecosystem</span>
          <div className='ecosystem_grid'>
            <div className='ecosystem_card blue_card'>
              <h3 className='ecosystem_title'>Faith Meta Card</h3>
              <img src='assets/image/virtual_card.webp' alt='faith protocol virtual card' />
              <p className='ecosystem_content'>Meta Card is your Web3 virtual identity, allowing easy sharing of contact details and social links, with instant updates for seamless networking. Create yours now!</p>
              <a href='https://www.faithprotocol.com/#about' target='_blank' className='signin_btn account_btn text-center'>Learn More</a>
            </div>
            <div className='ecosystem_card yellow_card'>
              <h3 className='ecosystem_title'>Meta Education</h3>
              <img src='assets/image/meta_education.webp' alt='faith protocol meta education' />
              <p className='ecosystem_content'>Meta Education delivers an interactive learning experience in the metaverse, featuring virtual classrooms, courses, and educational resources, all enhanced by blockchain for secure and engaging education.</p>
              <a href='https://www.faithprotocol.com/#learning' target='_blank' className='signin_btn account_btn text-center'>Learn More</a>
            </div>
            <div className='ecosystem_card green_card'>
              <h3 className='ecosystem_title text-white'>Meta Job</h3>
              <img src='assets/image/meta_job.webp' alt='faith protocol meta job' />
              <p className='ecosystem_content text-white'>Meta Job connects users with virtual job opportunities, offering a dynamic platform for career development, networking, and recruitment within the metaverse, all supported by blockchain technology.</p>
              <a href='https://www.faithprotocol.com/#services' target='_blank' className='signin_btn account_btn text-center'>Learn More</a>
            </div>
            <div className='ecosystem_card gradient_light_pink ecosystem_item'>
              <h3 className='ecosystem_title text-white'>Faith Social-Fi</h3>
              <img src='assets/image/social_fi.webp' alt='faith protocol social-Fi' />
              <p className='ecosystem_content text-white'>Faith Protocol's Social-Fi creates a secure, decentralized platform for social interactions, integrating blockchain to reward user engagement and content sharing.</p>
              <a href='https://faithmeta.land/' target='_blank' className='signin_btn account_btn text-center'>Learn More</a>
            </div>
            <div className='ecosystem_card teal_blue'>
              <h3 className='ecosystem_title text-white'>Faith Virtual Tour</h3>
              <img src='assets/image/virtual_tour.webp' alt='faith protocol virtual tour' />
              <p className='ecosystem_content text-white'>Faith Protocol's Virtual Tour offers immersive experiences, allowing users to explore digital environments, landmarks, and events through cutting-edge virtual reality technology.</p>
              <a href='https://faithmeta.land/' target='_blank' className='signin_btn account_btn text-center'>Learn More</a>
            </div>
            <div className='ecosystem_card orange_card'>
              <h3 className='ecosystem_title text-white'>Faith Daily Task</h3>
              <img src='assets/image/daily_task.webp' alt='faith protocol daily task' />
              <p className='ecosystem_content text-white'>Faith Protocol's Daily Task feature incentivizes users to complete daily missions and activities, earning rewards in the ecosystem's native tokens.</p>
              <a href='https://www.faithprotocol.com/#services' target='_blank' className='signin_btn account_btn text-center'>Learn More</a>
            </div>
            <div className='ecosystem_card magenta_card'>
              <h3 className='ecosystem_title text-white'>Meta-Event</h3>
              <img src='assets/image/meta_event.webp' alt='faith protocol daily task' />
              <p className='ecosystem_content text-white'>Meta-Event on Faith Protocol is a dynamic virtual gathering space, bringing communities together for immersive experiences, networking, and interactive digital events.</p>
              <a href='https://faithmeta.land/' target='_blank' className='signin_btn account_btn text-center'>Learn More</a>
            </div>
            <div className='ecosystem_card darkblue_card'>
              <h3 className='ecosystem_title text-white'>Faith City</h3>
              <img src='assets/image/faith_city.webp' alt='faith protocol daily task' />
              <p className='ecosystem_content text-white'>Faith Protocol's Faith City offers customizable virtual residences, social hubs, and communal spaces, fostering connections and a sense of community in the metaverse.</p>
              <a href='https://faithmeta.land/' target='_blank' className='signin_btn account_btn text-center'>Learn More</a>
            </div>
            <div className='ecosystem_card deepcyan_card'>
              <h3 className='ecosystem_title text-white'>Faith Meta Meet</h3>
              <img src='assets/image/meta_meet.webp' alt='faith protocol meta meet' />
              <p className='ecosystem_content text-white'>Faith Meta Meet is a cutting-edge virtual meeting platform, utilizing blockchain for secure, immersive conferencing experiences with advanced features for seamless collaboration and interaction.</p>
              <a href='https://www.faithprotocol.com/#GameFi' target='_blank' className='signin_btn account_btn text-center'>Learn More</a>
            </div>
            <div className='ecosystem_card purple_card wellness_card'>
              <h3 className='ecosystem_title text-white'>Health & Wellness</h3>
              <img src='assets/image/helth_wellness.webp' alt='faith protocol meta meet' />
              <p className='ecosystem_content text-white'>Faith Protocol's Health & Wellness platform provides virtual spaces for holistic well-being, offering personalized health guidance, fitness routines, and mental wellness resources in an immersive environment.</p>
              <a href='https://www.faithprotocol.com/#learning' target='_blank' className='signin_btn account_btn text-center'>Learn More</a>
            </div>
            <div className='ecosystem_card darkblue_card ecosystem_item'>
              <h3 className='ecosystem_title text-white'>Faith Citizenship card</h3>
              <img src='assets/image/citizenship.webp' alt='faith protocol social-Fi' />
              <p className='ecosystem_content text-white'>A unique digital ID granting access to exclusive Faith Protocol privileges, services, and experiences within the metaverse ecosystem.</p>
              <a href='https://www.faithprotocol.com/#strategy' target='_blank' className='signin_btn account_btn text-center'>Learn More</a>
            </div>
            <div className='ecosystem_card orange_card'>
              <h3 className='ecosystem_title text-white'>Meta Office</h3>
              <img src='assets/image/meta_office.webp' alt='faith protocol meta office' />
              <p className='ecosystem_content text-white'>A virtual workspace offering customizable environments for remote collaboration, meetings, and presentations, enhancing productivity and global connectivity.</p>
              <a href='https://www.faithprotocol.com/#meetings' target='_blank' className='signin_btn account_btn text-center'>Learn More</a>
            </div>
            <div className='ecosystem_card green_card'>
              <h3 className='ecosystem_title text-white'>Gami-FI</h3>
              <img src='assets/image/gami_fI.webp' alt='faith protocol gami fI' />
              <p className='ecosystem_content text-white'>A gamified financial platform integrating play-to-earn mechanics, allowing users to earn rewards through interactive gaming experiences within Faith Protocol.</p>
              <a href='https://www.faithprotocol.com/#webgame' target='_blank' className='signin_btn account_btn text-center'>Learn More</a>
            </div>
            <div className='ecosystem_card purple_card wellness_card'>
              <h3 className='ecosystem_title text-white'>Faith Entertainment Zone</h3>
              <img src='assets/image/entertainment_zone.webp' alt='faith protocol entertainment zone' />
              <p className='ecosystem_content text-white'>A vibrant digital hub featuring virtual concerts, movies, and interactive events, offering endless entertainment options in the metaverse.</p>
              <a href='https://faithmeta.land/' target='_blank' className='signin_btn account_btn text-center'>Learn More</a>
            </div>
            <div className='ecosystem_card skyblue_card ecosystem_item'>
              <h3 className='ecosystem_title text-white'>Cloud Farming</h3>
              <img src='assets/image/cloud_farming.webp' alt='faith protocol cloud farming' />
              <p className='ecosystem_content text-white'>Engage in virtual farming, grow digital assets, and earn rewards through innovative yield farming mechanics on the Faith Protocol platform.</p>
              <a href='https://www.faithprotocol.com/#farming' target='_blank' className='signin_btn account_btn text-center'>Learn More</a>
            </div>
            <div className='ecosystem_card blue_card'>
              <h3 className='ecosystem_title text-white'>AI Trading Bot</h3>
              <img src='assets/image/trading_bot.webp' alt='faith protocol trading bot' />
              <p className='ecosystem_content text-white'>An intelligent trading assistant powered by AI, automating crypto trading strategies for optimized results in the Faith Protocol ecosystem.</p>
              <a href='https://www.faithprotocol.com/#trading' target='_blank' className='signin_btn account_btn text-center'>Learn More</a>
            </div>
            <div className='ecosystem_card steel_blue_card'>
              <h3 className='ecosystem_title text-white'>Faith Real Estate</h3>
              <img src='assets/image/real_estate.webp' alt='faith protocol real estate' />
              <p className='ecosystem_content text-white'>Invest, buy, sell, or lease virtual properties within the Faith Protocol, creating new opportunities in digital real estate.</p>
              <a href='https://faithmeta.land/' target='_blank' className='signin_btn account_btn text-center'>Learn More</a>
            </div>
            <div className='ecosystem_card lavander_card wellness_card'>
              <h3 className='ecosystem_title text-white'>Meta E-commerce</h3>
              <img src='assets/image/meta_ecommerce.webp' alt='faith protocol meta ecommerce' />
              <p className='ecosystem_content text-white'>A virtual marketplace where users can buy and sell digital goods and services, revolutionizing shopping experiences within the metaverse.</p>
              <a href='https://www.faithprotocol.com/#shopping' target='_blank' className='signin_btn account_btn text-center'>Learn More</a>
            </div>
            <div className='ecosystem_card purple_card'>
              <h3 className='ecosystem_title text-white'>Faith Web3 Wallet</h3>
              <img src='assets/image/faith_wallet.webp' alt='faith protocol wallet' />
              <p className='ecosystem_content text-white'>A secure, decentralized Web3 wallet enabling safe storage, management, and transactions of digital assets within Faith Protocol’s ecosystem.</p>
              <a href='https://www.faithprotocol.com/#wallet' target='_blank' className='signin_btn account_btn text-center'>Learn More</a>
            </div>
            <div className='ecosystem_card orange_card'>
              <h3 className='ecosystem_title text-white'>Faith ANFT</h3>
              <img src='assets/image/anft.webp' alt='faith protocol ANFT' />
              <p className='ecosystem_content text-white'>Advanced NFTs (ANFTs) offering dynamic and interactive features, unlocking new levels of creativity and utility in the digital asset space.</p>
              <a href='https://www.faithprotocol.com/#anft' target='_blank' className='signin_btn account_btn text-center'>Learn More</a>
            </div>
            <div className='ecosystem_card midnight_purple_card'>
              <h3 className='ecosystem_title text-white'>Faith Exchange</h3>
              <img src='assets/image/exchange.webp' alt='faith protocol exchange' />
              <p className='ecosystem_content text-white'>A user-friendly, secure cryptocurrency exchange platform offering a wide range of trading pairs, real-time market data, and liquidity solutions.</p>
              <a href='https://www.faithprotocol.com/#segments' target='_blank' className='signin_btn account_btn text-center'>Learn More</a>
            </div>
            <div className='ecosystem_card dark_violet_card ecosystem_item'>
              <h3 className='ecosystem_title text-white'>Faith Blockchain</h3>
              <img src='assets/image/blockchain.webp' alt='faith protocol blockchain' />
              <p className='ecosystem_content text-white'>A scalable and secure blockchain network supporting decentralized applications, smart contracts, and seamless transactions within the Faith Protocol.</p>
              <a href='#' className='signin_btn account_btn text-center'>Coming Soon</a>
            </div>
            <div className='ecosystem_card neonpink_card'>
              <h3 className='ecosystem_title text-white'>Faith Life</h3>
              <img src='assets/image/faith_life.webp' alt='faith protocol life' />
              <p className='ecosystem_content text-white'>An immersive, interconnected digital world where users can live, work, socialize, and thrive within the Faith Protocol’s metaverse.</p>
              <a href='https://faithmeta.land/' target='_blank' className='signin_btn account_btn text-center'>Learn More</a>
            </div>
            <div className='ecosystem_card neonpink_card'>
              <h3 className='ecosystem_title text-white text-center'>XR Magic</h3>
              <img src='assets/image/xr_magic.webp' alt='faith protocol xr magic' />
              <p className='ecosystem_content text-white'>Cutting-edge extended reality (XR) technology that merges the physical and digital worlds, enhancing virtual experiences within Faith Protocol.</p>
              <a href='https://www.faithprotocol.com/#anft' target='_blank' className='signin_btn account_btn text-center'>Learn More</a>
            </div>
            <div className='ecosystem_card neonpink_card'>
              <h3 className='ecosystem_title text-white text-center'>Faith Unit</h3>
              <img src='assets/image/faith_unit.webp' alt='faith protocol unit' />
              <p className='ecosystem_content text-white'>Faith Unity is a realistic 3D platform designed for enthusiasts, offering immersive, high-quality 3D environments and interactive experiences tailored to diverse interests and applications.</p>
              <a href='#' className='signin_btn account_btn text-center'>Coming Soon</a>
            </div>
            <div className='ecosystem_card neonpink_card'>
              <h3 className='ecosystem_title text-white text-center'>Faith FAIX</h3>
              <img src='assets/image/faith_eaix.webp' alt='faith protocol eaix' />
              <p className='ecosystem_content text-white'>Faith Protocol's AI-powered assistant offering personalized support, insights, and automation for enhanced user experiences within the metaverse.</p>
              <a href='#' className='signin_btn account_btn text-center'>Coming Soon</a>
            </div>
          </div>
        </div>
      </section>

      <section className='section_detail presale_section presale_bg'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-9'>
              <h2 className='presale_title gredient_text'>CORE STRENGTHS</h2>
              <span className='presale_title gredient_text'>of Faith Protocol</span>
              <p className='color_white_opacity_70 mt-3 mb-md-5 mb-4 attribute_last'>Faith Protocol excels with its robust self built infrastructure, diverse digital tools, integrated financial solutions, immersive virtual environments, and cutting-edge AI capabilities. Stay tuned for our upcoming presale to be at the forefront of these innovations and help shape the future of decentralized technology.</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-5'>
              <div className='attribute_card attribute_ecosystem'>
                <div>
                  <h3 className='attribute_title gredient_text'>Community- <br className='d-lg-block d-none' />Centric Innovation</h3>
                  <p className='attribute_detail'>Faith Protocol actively involves its community in shaping the platform's evolution. By engaging users in feedback and development processes, the platform ensures that its features and services align with the needs and preferences of its user base.</p>
                </div>
              </div>
            </div>
            <div className='col-lg-7'>
              <div className='attribute_card attribute_developer'>
                <div>
                  <h3 className='attribute_title gredient_text'>All-in-One Digital Solutions</h3>
                  <p className='attribute_detail'>Access a range of tools, including virtual cards, AI assistants, and metaverse platforms. Enhance your Web3 experience with our integrated solutions—be part of the presale and transform your digital world.</p>
                </div>
              </div>
            </div>
            <div className='col-lg-7'>
              <div className='attribute_card attribute_community'>
                <div>
                  <h3 className='attribute_title gredient_text'>Unified <br className='d-lg-block d-none' />Financial System</h3>
                  <p className='attribute_detail attribute_content'>Faith Protocol’s ecosystem features Faith Coin for seamless transactions in cloud farming, e-commerce, and more. Join the presale soon to benefit from our comprehensive financial system and ensure transparent and efficient operations.</p>
                </div>
              </div>
            </div>
            <div className='col-lg-5'>
              <div className='attribute_card attribute_mechanism attribute_partnership_content'>
                <div>
                  <h3 className='attribute_title gredient_text'>AI-Powered <br className='d-lg-block d-none' />Future</h3>
                  <p className='attribute_detail'>Harness the power of AI integration with Faith Protocol’s digital assistant, enhancing your interactions and experiences. Be ready for the presale and be at the forefront of the future with advanced AI capabilities.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className='section_detail presale_section'>
        <div className='container'>
          <div className='overflow-hidden'>
            <h4 className='news_title gredient_text'>News Media</h4>
            <Swiper
              speed={1500}
              spaceBetween={20}
              autoplay={false}
              navigation={{
                nextEl: ".slider_next",
                prevEl: ".slider_prev",
              }}
              pagination={false}
              draggable={true}
              loop={true}
              breakpoints={{
                578: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 1.8,
                },
                820: {
                  slidesPerView: 2,
                },
                991: {
                  slidesPerView: 2.3,
                },
                1024: {
                  slidesPerView: 2,
                },
                1366: {
                  slidesPerView: 3,
                },
              }}
              modules={[Navigation, Pagination, Autoplay]}
              className="mySwiper swiper_slide"
            >
              <SwiperSlide>
                <div>
                  <div className='news_img'>
                  </div>
                  <h5 className='news_card_title'>Blog 1</h5>
                  <div className='d-flex align-items-center gap-2'>
                    <p className='news_date'>Jun 19, 2024</p>
                    <a href='#' className='news_link text_color_gradient'>Read More
                      <img src='/assets/image/color_arrow_right.svg' alt='blog arrow' />
                    </a>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <div className='news_img'>
                  </div>
                  <h5 className='news_card_title'>Blog 2</h5>
                  <div className='d-flex align-items-center gap-2'>
                    <p className='news_date'>Jun 19, 2024</p>
                    <a href='#' className='news_link text_color_gradient'>Read More
                      <img src='/assets/image/color_arrow_right.svg' alt='blog arrow' />
                    </a>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <div className='news_img'>
                  </div>
                  <h5 className='news_card_title'>Blog 3</h5>
                  <div className='d-flex align-items-center gap-2'>
                    <p className='news_date'>Jun 19, 2024</p>
                    <a href='#' className='news_link text_color_gradient'>Read More
                      <img src='/assets/image/color_arrow_right.svg' alt='blog arrow' />
                    </a>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <div className='news_img'>
                  </div>
                  <h5 className='news_card_title'>Blog 4</h5>
                  <div className='d-flex align-items-center gap-2'>
                    <p className='news_date'>Jun 19, 2024</p>
                    <a href='#' className='news_link text_color_gradient'>Read More
                      <img src='/assets/image/color_arrow_right.svg' alt='blog arrow' />
                    </a>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <div className='news_img'>
                  </div>
                  <h5 className='news_card_title'>Blog 5</h5>
                  <div className='d-flex align-items-center gap-2'>
                    <p className='news_date'>Jun 19, 2024</p>
                    <a href='#' className='news_link text_color_gradient'>Read More
                      <img src='/assets/image/color_arrow_right.svg' alt='blog arrow' />
                    </a>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <div className='news_img'>
                  </div>
                  <h5 className='news_card_title'>Blog 6</h5>
                  <div className='d-flex align-items-center gap-2'>
                    <p className='news_date'>Jun 19, 2024</p>
                    <a href='#' className='news_link text_color_gradient'>Read More
                      <img src='/assets/image/color_arrow_right.svg' alt='blog arrow' />
                    </a>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <div className='news_img'>
                  </div>
                  <h5 className='news_card_title'>Blog 7</h5>
                  <div className='d-flex align-items-center gap-2'>
                    <p className='news_date'>Jun 19, 2024</p>
                    <a href='#' className='news_link text_color_gradient'>Read More
                      <img src='/assets/image/color_arrow_right.svg' alt='blog arrow' />
                    </a>
                  </div>
                </div>
              </SwiperSlide>

              <div className='d-flex align-items-center slider_btn'>
                <div className='slider_prev me-2'>
                  <img src="/assets/image/slider_left_arrow.svg" loading="lazy" alt="slider prev" />
                </div>
                <div className="slider_prev slider_next">
                  <img src="/assets/image/slider_left_arrow.svg" loading="lazy" alt="slider next" />
                </div>
              </div>
            </Swiper>
          </div>
        </div>
      </section> */}

      <footer className='section_detail presale_section footer_section'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4 mb-lg-0 mb-4'>
              <a href='/'>
                <img src='assets/image/logo.svg' alt='Faith Protocol Logo' />
              </a>
              <p className='color_white_opacity_70 mt-3 mb-4'>The Faith Protocol Eco-System is unique in itself as it is completely based on its own techs.</p>
              <div className='d-flex align-items-center gap-3'>
                <a href='https://www.instagram.com/faithprotocol/' target='_blank'>
                  <img src='assets/image/Instagram.svg' alt='Instagram' />
                </a>
                <a href='https://x.com/Faith_Protocol' target='_blank'>
                  <img src='assets/image/Twitter.svg' alt='Twitter' />
                </a>
                <a href='https://www.facebook.com/FAITHPROTOCOL' target='_blank'>
                  <img src='assets/image/Facebook.svg' alt='Facebook' />
                </a>
                <a href='https://t.me/Faithprotocol_official' target='_blank'>
                  <img src='assets/image/Telegram.svg' alt='Telegram' />
                </a>
                <a href='https://medium.com/@faithprotocol' target='_blank'>
                  <img src='assets/image/medium.svg' alt='Medium' />
                </a>
                <a href='https://www.youtube.com/@faithprotocol_official' target='_blank'>
                  <img src='assets/image/youtube.svg' alt='YouTube' />
                </a>
              </div>
            </div>
            <div className='col-lg-2 col-md-4 col-6 offset-lg-1'>
              <p className='footer_p'>Introduction</p>
              <ul>
                <li><a href='#'>About Us</a></li>
                <li><a href='#ecosystem'>Ecosystem</a></li>
                <li><a href='#'>Mission 369</a></li>
                <li><a href='#'>Purpose</a></li>
                <li><a href='#'>Consensus</a></li>
                <li><a href='#'>Tokenomics</a></li>
                <li><a href='#'>Roadmap</a></li>
              </ul>
            </div>
            <div className='col-lg-2 col-md-4 col-6'>
              <p className='footer_p'>Tools</p>
              <ul>
                <li><a href='https://portal.faithprotocol.io/wallet' target='_blank'>Wallet</a></li>
                <li><a href='https://bscscan.com/token/0xfaf449b8343867eeb9a7f220d8004e1a8c508c41' target='_blank'>Explorer</a></li>
                <li><a href='#'>Bridges</a></li>
                <li><a href='#'>Documentation</a></li>
                <li><a href='#'>Mainnet</a></li>
                <li><a href='#'>Faucets</a></li>
                <li><a href='#'>Launch NFTs</a></li>
              </ul>
            </div>
            <div className='col-lg-3 col-md-4 mt-md-0 mt-3'>
              <p className='footer_p'>Documentation</p>
              <ul>
                <li><a href='https://faith-protocol.gitbook.io/faith-protocol-whitepaper' target='_blank'>Whitepaper</a></li>
                <li><a href='#'>Brand Kit</a></li>
                <li><a href='#'>News & Updates</a></li>
                <li><a href='#'>Developer Documentation</a></li>
                <li><a href='#'>Dev Forum</a></li>                
              </ul>
            </div>
          </div>
          <div className='footer_border'></div>
          <div className='d-lg-flex align-items-center justify-content-between'>
            <p className='color_white_opacity_70'>@2024 registered Faith Protocol.</p>
            <p className='color_white_opacity_70 mt-lg-0 mt-2'>Ready to join Faith Protocol's presale now! Enjoy exclusive benefits and secure your spot in the future!</p>
          </div>
        </div>
      </footer>

      <SeedPopup show={show} />
    </>
  );
}

export default App;